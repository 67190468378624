<template>
  <div
    v-if="isVisible"
    class="event-duplicate-button"
    :style="{
      maxWidth: `${maxWidth}px`,
    }"
  >
    <v-btn
      text
      class="event-duplicate-button__btn"
      color="blue"
      :disabled="loading"
      :loading="loading"
      @click="toggle"
    >
      Duplicate
    </v-btn>

    <ConfirmModal
      :value="showModal"
      :title="confirmationTitle"
      :message="confirmationMessage"
      :confirm-color="confirmColor"
      :confirm-text="confirmText"
      :cancel-color="cancelColor"
      :cancel-text="cancelText"
      :loading="loading"
      @cancel="close"
      @confirm="duplicate"
      @input="toggle"
    ></ConfirmModal>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * Event Duplicate Button
 * ==================================================================================
 **/
import { mapActions } from 'vuex'
import { Model } from 'vue-api-query'
import { capitalize } from '@/utils/helpers'
import ConfirmModal from '@/components/modals/ConfirmModal'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'

export default {
  components: {
    ConfirmModal,
  },
  mixins: [SnackbarMixin, ErrorHandlerMixin],
  props: {
    event: {
      type: Model,
      default: () => {
        return null
      },
    },

    title: {
      type: String,
      default: 'Delete?',
    },

    cancelColor: {
      type: String,
      default: 'red',
    },

    cancelText: {
      type: String,
      default: 'Cancel',
    },

    confirmColor: {
      type: String,
      default: 'green',
    },

    confirmText: {
      type: String,
      default: 'Confirm',
    },

    maxWidth: {
      type: Number,
      default: 400,
    },
  },

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  computed: {
    nameCapitalized() {
      return this.event ? capitalize(this.event.title) : null
    },

    confirmationTitle() {
      return `Duplicate Event`
    },

    confirmationMessage() {
      return `Are you sure you want to duplicate the ${this.nameCapitalized} event?`
    },

    isArchived() {
      return this.model ? !!this.model.deleted_at : false
    },

    isVisible() {
      return !!this.event
    },
  },

  methods: {
    ...mapActions({
      duplicateEvent: 'event/duplicateEvent',
      getBlockTimeSlotList: 'event/getBlockTimeSlotList',
    }),

    toggle(show = null) {
      this.showModal =
        show === null || typeof show !== 'boolean' ? !this.showModal : show
    },
    open() {
      this.toggle(true)
    },
    close() {
      this.toggle(false)
    },

    async duplicate() {
      if (this.loading) return
      this.loading = true

      await this.duplicateEvent({
        id: this.event.id,
      })
        .then((data) => {
          this.showSnackbar(`${this.nameCapitalized} duplicated successfully!`)
          this.close()
          this.fetchBlockTimeSlotList(null, data.id)

          this.$router.push({
            name: 'event.list.details',
            params: {
              id: data.id,
            },
            query: { tab: 0 },
          })
        })
        .catch((error) => {
          this.showSnackbar(this.getErrorMessage(error), false)
          this.close()
        })
        .finally(() => {
          this.loading = false
        })
    },

    async fetchBlockTimeSlotList(options, id) {
      this.loading = true

      const currentPage = options?.page || 1

      try {
        await this.getBlockTimeSlotList({
          id: id,
          page: currentPage,
          sort: options?.sortBy[0]
            ? options.sortDesc[0]
              ? '-' + options.sortBy[0]
              : options.sortBy[0]
            : '',
          filterPast: this.false,
        })
      } catch (err) {
        this.showSnackbar(this.getErrorMessage(err), false)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.event-duplicate-button {
  text-align: right;

  &__btn {
    font-weight: bold;
  }
}
</style>
